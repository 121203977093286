import {
  Box,
  FormItem,
  Input,
  PhoneInputCountrySelect,
} from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import {
  ContentCard,
  ContentCardBackButton,
  ContentCardBody,
  ContentCardButton,
  ContentCardFooter,
  ContentCardHeading,
} from '@/components/contentCard'
import { useAppNavigate } from '@/hooks/useAppNavigate/useAppNavigate'
import {
  WorkerLookupFormSchema,
  workerLookupFormSchema,
} from '@/lib/formSchemas/workerLookup'
import { useOrg } from '@/queries/organizations'
import { useLookupWorker } from '@/queries/workers/workers'

export const WorkerLookup = () => {
  const navigate = useAppNavigate()

  const {
    data: { name: companyName },
  } = useOrg()

  const {
    formState: { isValid, errors },
    control,
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(workerLookupFormSchema),
  })

  const { mutate: lookupWorker, isPending: lookingUpWorker } = useLookupWorker()

  const onSubmit = ({ email, phoneNumber }: WorkerLookupFormSchema) => {
    lookupWorker(
      { email, phone: phoneNumber },
      {
        onSuccess: ({ data: { worker_id } }) => {
          void navigate({ to: '/verificationSelection', search: { worker_id } })
        },
      }
    )
  }

  return (
    <ContentCard as="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentCardBackButton />
      <ContentCardBody>
        <ContentCardHeading
          subtitle={`We'll use these contact methods to confirm your workplace is ${companyName}.`}
        >
          {`Enter the email and phone number on file with ${companyName} to get started with Branch.`}
        </ContentCardHeading>
        <Box $display="flex" $direction="column" $align="stretch" $gap={4}>
          <FormItem error={errors.email?.message}>
            <Input
              label="Email address"
              placeholder="Enter email"
              autoFocus
              {...register('email')}
            />
          </FormItem>
          <FormItem
            error={errors.phoneNumber?.message}
            description="Standard messaging rates may apply."
          >
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue=""
              render={({ field: { onChange, value, ...restField } }) => (
                <PhoneInputCountrySelect
                  label="Phone number"
                  placeholder="Enter phone"
                  onChange={value => {
                    onChange(value)
                  }}
                  value={value}
                  {...restField}
                />
              )}
            />
          </FormItem>
        </Box>
      </ContentCardBody>
      <ContentCardFooter>
        <ContentCardButton
          loading={lookingUpWorker}
          type="submit"
          disabled={!isValid}
        >
          {isValid ? 'Continue' : 'Enter Info'}
        </ContentCardButton>
      </ContentCardFooter>
    </ContentCard>
  )
}
