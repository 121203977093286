import { Toaster } from '@branch-messenger/willow-ui'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useBlocker } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/react-router-devtools'
import { FC } from 'react'

import { GlobalAlert } from '../globalAlert'
import { AppBody } from './AppBody'
import { AppFooter } from './AppFooter'
import { AppHeader } from './AppHeader'
import { StyledAppLayout } from './styles'

export const AppLayout: FC = () => {
  useBlocker({ shouldBlockFn: () => false }) //block beforeUnload event for refreshes or browser based navigation

  return (
    <>
      <Toaster />
      <GlobalAlert />
      <StyledAppLayout>
        <AppHeader />
        <AppBody />
        <AppFooter />
      </StyledAppLayout>
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />
    </>
  )
}
