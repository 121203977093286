import { Box, Card, QRCode } from '@branch-messenger/willow-ui'

import {
  ContentCard,
  ContentCardBackButton,
  ContentCardBody,
  ContentCardHeading,
} from '@/components/contentCard'
import { useOrg } from '@/queries/organizations'

export const AppQRCode = () => {
  const { data: orgData } = useOrg()
  return (
    <ContentCard>
      <ContentCardBackButton />
      <ContentCardBody>
        <Box $display="flex" $justify="center">
          <Card $variant="elevated" $display="flex" $p={6} $radii="xl">
            <QRCode value={orgData.login_deep_link} />
          </Card>
        </Box>
        <ContentCardHeading
          subtitle={`Scan the QR code above to open the ${orgData.settings.app_display_name} app on your mobile device.`}
        >
          {`Access your digital wallet and manage funds in the ${orgData.settings.app_display_name} app.`}
        </ContentCardHeading>
      </ContentCardBody>
    </ContentCard>
  )
}
