import { Box, Icon, Typography } from '@branch-messenger/willow-ui'
import { ArrowLeft } from '@branch-messenger/willow-ui/icons'
import { FC } from 'react'

import { useAppNavigate } from '@/hooks/useAppNavigate/useAppNavigate'
import { useNavigationHistoryStore } from '@/store/navigationHistory'

import { StyledBackButton, StyledBackButtonContainer } from './styles'
import { ContentCardBackButtonProps } from './types'

export const ContentCardBackButton: FC<ContentCardBackButtonProps> = ({
  title,
}) => {
  const navigate = useAppNavigate()
  const getPreviousState = useNavigationHistoryStore(
    store => store.getPreviousState
  )
  const history = useNavigationHistoryStore(store => store.history)
  const popHistory = useNavigationHistoryStore(store => store.popHistory)
  const previousState = getPreviousState()

  if (history.length < 2) {
    return null
  }

  const handleBackClick = () => {
    popHistory()
    return navigate({ ...previousState, saveHistory: false })
  }

  return (
    <StyledBackButtonContainer>
      <Box $display="flex">
        <StyledBackButton data-testid="backButton" onClick={handleBackClick}>
          <Icon Icon={ArrowLeft} size={6} />
        </StyledBackButton>
      </Box>
      {!!title && (
        <Box $display="flex" $justify="center">
          <Typography $color="textMuted" $size="sm" $italicized>
            {title}
          </Typography>
        </Box>
      )}
    </StyledBackButtonContainer>
  )
}
