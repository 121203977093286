import { parsePhoneNumber } from '@branch-messenger/willow-ui'
import { create } from 'zustand'

import { CreateAccountForm } from './createAccountForm.types'

/**
 * This store is used to manage the state of data used for creating accounts.
 * The forms for account creation can span multiple pages depending on the account type being created.
 * We use this store to keep track of the data throughout the flow.
 */

export const useCreateAccountFormState = create<CreateAccountForm>(set => ({
  address: undefined,
  date_of_birth: undefined,
  passcode: undefined,
  phone: undefined,
  email: undefined,
  password: undefined,
  confirm_password: undefined,
  setCreateAccountFormState: newState => set(newState),
}))

export const useParsedPhoneNumber = () => {
  const phone = useCreateAccountFormState(state => state.phone)
  return parsePhoneNumber(phone || '', { defaultCountry: 'US' })
}
