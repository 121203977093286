import { useMutation, useMutationState } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'
import axios from 'axios'
import queryString from 'query-string'

import {
  authorizeDirectDeposit,
  createDirectAccount,
  CreateDirectAccountConflictResponse,
  CreateDirectAccountPayload,
  createUser,
  CreateUserPayload,
  createWallet,
  CreateWalletPayload,
  CreateWalletResponse,
  DirectDepositAuthorizationPayload,
  linkAccount,
  LinkAccountPayload,
  LinkAccountResponse,
} from '@/api/accounts'
import { getExchangeToken, getPasscode } from '@/api/auth'
import { AlternateErrorResponse } from '@/api/types'
import {
  checkUserWalletExists,
  CheckUserWalletExistsPayload,
} from '@/api/workers'
import { useAppNavigate } from '@/hooks/useAppNavigate/useAppNavigate'
import { identifyAnalyticsUser, sendAnalyticsEvent } from '@/lib/analytics'
import { handleHttpException } from '@/lib/httpExceptionHandler'
import { useAlertStore } from '@/store/alert'

const CREATE_WALLET_MUTATION_KEY = 'CREATE_WALLET_MUTATION_KEY'
export const useCreateWalletMutation = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationKey: [CREATE_WALLET_MUTATION_KEY],
    mutationFn: async (req: CreateWalletPayload) => {
      const response = await createWallet(req)
      return response.data
    },
    onSuccess: () => {
      sendAnalyticsEvent('WALLET', 'create-wallet:success')
      return navigate({ to: '/directDepositAuthorization' })
    },
    onError: () => {
      showAlert({
        title: 'Error',
        description:
          'An error occurred while creating your account. Please try again later.',
        hideCancel: true,
      })
    },
    gcTime: Infinity,
  })
}

export const useCreateWalletMutationState = () => {
  const [data] = useMutationState<CreateWalletResponse>({
    filters: { mutationKey: [CREATE_WALLET_MUTATION_KEY] },
    select: mutation => {
      return mutation.state.data as CreateWalletResponse
    },
  })

  return data?.data
}

const exchangeToken = async (token: string) => {
  const exchangeTokenResponse = await getExchangeToken(token)
  const userId = exchangeTokenResponse.data?.data?.userId
  userId && identifyAnalyticsUser(userId)
}

const useCheckUserWalletExists = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)
  return useMutation({
    mutationFn: async (payload: CheckUserWalletExistsPayload) => {
      const response = await checkUserWalletExists(payload)
      return response.data
    },
    onSuccess: async ({ has_wallet, token }) => {
      if (has_wallet) {
        return navigate({ to: '/linkAccount' })
      }
      try {
        await exchangeToken(token)
        const { data } = await getPasscode()
        if (data?.data?.has_access_code === 'true') {
          return navigate({
            to: '/terms',
            search: {
              agreementType: 'essential',
            },
          })
        }
        void navigate({ to: '/passcode' })
      } catch (e) {
        showAlert({
          title: 'Error',
          description:
            'An error occurred while creating your account. Please try again later.',
          hideCancel: true,
        })
      }
    },
    onError: () => {
      void navigate({ to: '/linkAccount' })
    },
  })
}

export const useCreateUserMutation = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)
  const { mutateAsync: checkWalletExists } = useCheckUserWalletExists()

  return useMutation({
    mutationFn: async (payload: CreateUserPayload) => {
      const response = await createUser(payload)
      await exchangeToken(response.data.token)
    },
    onSuccess: () => {
      sendAnalyticsEvent('WALLET', 'create-user:success')
      return navigate({ to: '/passcode' })
    },
    onError: async (error, { phone }) => {
      const handleGenericError = () => {
        showAlert({
          title: 'Error',
          description:
            'An error occurred while creating your account. Please try again later.',
          hideCancel: true,
        })
      }
      await handleHttpException<AlternateErrorResponse>(error, {
        onHttpError: async ({ response }) => {
          if (response?.data.status === 409) {
            return checkWalletExists({ phone })
          }
          handleGenericError()
        },
        onOtherError: handleGenericError,
      })
    },
  })
}

const LINK_ACCOUNT_MUTATION_KEY = 'LINK_ACCOUNT_MUTATION_KEY'
export const useLinkAccountMutation = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationKey: [LINK_ACCOUNT_MUTATION_KEY],
    mutationFn: async (payload: LinkAccountPayload) => {
      const response = await linkAccount(payload)
      await exchangeToken(response.data.token)
      return response.data
    },
    onSuccess: () => {
      sendAnalyticsEvent('WALLET', 'link-account:success')
      return navigate({
        to: '/directDepositAuthorization',
        search: { linkedAccount: true },
      })
    },
    onError: error => {
      const defaultTitle = 'Unable to submit.'
      const defaultDescription =
        'We are unable to submit your request at this time. Please try again in a few minutes.'
      void handleHttpException<AlternateErrorResponse>(error, {
        onHttpError: ({ response }) => {
          const isInvalidPin = response?.data?.status === 401
          showAlert({
            title: isInvalidPin ? 'Incorrect passcode.' : defaultTitle,
            description: isInvalidPin
              ? 'Your passcodes do not match. Please try again.'
              : defaultDescription,
            hideCancel: true,
          })
        },
        onOtherError: () => {
          showAlert({
            title: defaultTitle,
            description: defaultDescription,
            hideCancel: true,
          })
        },
      })
    },
    gcTime: Infinity,
  })
}

export const useLinkAccountMutationState = () => {
  const [data] = useMutationState<LinkAccountResponse>({
    filters: { mutationKey: [LINK_ACCOUNT_MUTATION_KEY] },
    select: mutation => {
      return mutation.state.data as LinkAccountResponse
    },
  })

  return data
}

export const useAuthorizeDirectDepositMutation = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)
  const { linkedAccount } = useSearch({
    from: '/_initialized/directDepositAuthorization',
  })

  return useMutation({
    mutationFn: (payload: DirectDepositAuthorizationPayload) =>
      authorizeDirectDeposit(payload),
    onSuccess: () =>
      navigate({ to: '/accountCreated', search: { linkedAccount } }),
    onError: () => {
      showAlert({
        title: 'Unable to submit.',
        description:
          'We are unable to submit your request at this time. Please try again in a few minutes.',
        hideCancel: true,
      })
    },
  })
}

const CREATE_DIRECT_ACCOUNT_MUTATION_KEY = 'CREATE_DIRECT_ACCOUNT_MUTATION_KEY'
export const useCreateDirectAccountMutation = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationKey: [CREATE_DIRECT_ACCOUNT_MUTATION_KEY],
    mutationFn: async (payload: CreateDirectAccountPayload) => {
      try {
        const res = await createDirectAccount(payload)
        return res.data
      } catch (e) {
        if (
          axios.isAxiosError<CreateDirectAccountConflictResponse>(e) &&
          e.response?.status === 409
        ) {
          if (e.response?.data?.linked_orgs) {
            return e.response.data
          }
        } else {
          throw e
        }
      }
    },
    onSuccess: data => {
      if (data && 'linked_orgs' in data) {
        return navigate({ to: '/linkDirectAccount' })
      }

      data?.user_id && identifyAnalyticsUser(`${data.user_id}`)
      sendAnalyticsEvent('DIRECT', 'create-account:success')
      const query = queryString.stringify({ token: data?.token })
      window.location.replace(
        `${import.meta.env.VITE_DIRECT_ONBOARDING_URL}/registration/token-exchange?${query}`
      )
    },
    onError: () => {
      showAlert({
        title: 'Error',
        description:
          'An error occurred while creating your account. Please try again later.',
        hideCancel: true,
      })
    },
    gcTime: Infinity,
  })
}

export const useCreateDirectAccountMutationState = () => {
  const [data] = useMutationState<CreateDirectAccountConflictResponse>({
    filters: { mutationKey: [CREATE_DIRECT_ACCOUNT_MUTATION_KEY] },
    select: mutation => {
      return mutation.state.data as CreateDirectAccountConflictResponse
    },
  })
  return data
}
