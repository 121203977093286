import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'

import { AlternateErrorResponse } from '@/api/types'
import {
  getWorkerId,
  GetWorkerIdParams,
  sendOTP,
  SendOTPPayload,
  sendOTPToNewVerificationMethod,
  SendOTPToNewVerificationMethodPayload,
  verifyOTP,
  verifyOTPFromNewVerificationMethod,
  VerifyOTPFromNewVerificationMethodPayload,
  VerifyOTPPayload,
  verifyWorkerPasscode,
  VerifyWorkerPasscodePayload,
  WorkerOTPInfo,
} from '@/api/workers'
import { useAppNavigate } from '@/hooks/useAppNavigate/useAppNavigate'
import { handleHttpException } from '@/lib/httpExceptionHandler'
import {
  getWorkerOrgsQueryOptions,
  getWorkerOtpInfoQueryOptions,
  workerInfoQueryOptions,
} from '@/queryOptions/workers'
import { useLoginOTPSuccess } from '@/routeComponents/otpVerification/hooks/useLoginOTPSuccess'
import { useAlertStore } from '@/store/alert'
import { Alert } from '@/store/alert.types'
import { useCreateAccountFormState } from '@/store/createAccountForm'

import {
  UseVerifyOTPFromLoginVerificationMethodProps,
  UseVerifyOTPFromNewVerificationMethodProps,
} from './types'

export const useWorkerOTPInfo = <TData = WorkerOTPInfo>(
  select?: (data: WorkerOTPInfo) => TData
) => {
  const { worker_id } = useSearch({ strict: false })
  return useSuspenseQuery({
    ...getWorkerOtpInfoQueryOptions({ worker_id: worker_id! }),
    select,
  })
}

export const useWorkerOrgs = () => {
  const phone = useCreateAccountFormState(state => state.phone)
  return useSuspenseQuery(getWorkerOrgsQueryOptions({ phone: phone! }))
}

export const useWorkerInfo = () => {
  return useSuspenseQuery(workerInfoQueryOptions)
}

export const useSendOTPToLoginVerificationMethod = () => {
  const showAlert = useAlertStore(state => state.showAlert)
  return useMutation({
    mutationFn: (payload: SendOTPPayload) => sendOTP(payload),
    onError: () => {
      showAlert({
        title: 'Failed to send OTP.',
        description: 'There was an issue sending OTP. Please try again later.',
        hideCancel: true,
      })
    },
  })
}

export const useVerifyOTPFromLoginVerificationMethod = (
  props?: UseVerifyOTPFromLoginVerificationMethodProps
) => {
  const showAlert = useAlertStore(state => state.showAlert)
  const handleLoginOTPSuccess = useLoginOTPSuccess()

  return useMutation({
    mutationFn: (payload: VerifyOTPPayload) => verifyOTP(payload),
    onError: () => {
      showAlert({
        title: 'Invalid code.',
        description:
          "Please double check that you've correctly entered the 6 digit code that was sent to you and try again.",
        hideCancel: true,
      })
    },
    onSuccess: async () => {
      await props?.onSuccess?.()
      return handleLoginOTPSuccess()
    },
  })
}

export const useSendOTPToNewVerificationMethod = () => {
  const showAlert = useAlertStore(state => state.showAlert)
  return useMutation({
    mutationFn: (payload: SendOTPToNewVerificationMethodPayload) =>
      sendOTPToNewVerificationMethod(payload),
    onError: () => {
      showAlert({
        title: 'Failed to send OTP.',
        description: 'There was an issue sending OTP. Please try again later.',
        hideCancel: true,
      })
    },
  })
}

export const useVerifyOTPFromNewVerificationMethod = (
  props?: UseVerifyOTPFromNewVerificationMethodProps
) => {
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationFn: (payload: VerifyOTPFromNewVerificationMethodPayload) =>
      verifyOTPFromNewVerificationMethod(payload),
    onError: () => {
      showAlert({
        title: 'Invalid code.',
        description:
          "Please double check that you've correctly entered the 6 digit code that was sent to you and try again.",
        hideCancel: true,
      })
    },
    onSuccess: async () => {
      try {
        await props?.onSuccess?.()
      } catch (error) {
        //do nothing, this is so the error can be handled by onSuccess, instead of the mutation handler above
      }
    },
  })
}

export const useLookupWorker = () => {
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationFn: (payload: GetWorkerIdParams) => getWorkerId(payload),
    onError: error => {
      const genericAlertInfo: Alert = {
        title: 'Failed to lookup worker.',
        description:
          'There was an issue looking up the worker. Please try again later.',
        hideCancel: true,
      }
      void handleHttpException<AlternateErrorResponse>(error, {
        onHttpError: e => {
          switch (e.response?.status) {
            case 404:
              showAlert({
                title: 'Info not found.',
                description:
                  'Please try again or contact your organization for assistance.',
                hideCancel: true,
              })
              break
            case 401:
              showAlert({
                title: e.response?.data?.error || genericAlertInfo.title,
                description:
                  e.response?.data?.message || genericAlertInfo.description,
                hideCancel: true,
              })
              break
            case 429:
              showAlert({
                title: 'Limit reached.',
                description:
                  "You've reached the maximum number of attempts for today. Please try again after 24 hours.",
                hideCancel: true,
              })
              break
            default:
              showAlert(genericAlertInfo)
          }
        },
        onOtherError: () => {
          showAlert(genericAlertInfo)
        },
      })
    },
  })
}

export const useVerifyWorkerPasscode = () => {
  const showAlert = useAlertStore(state => state.showAlert)
  const navigate = useAppNavigate()

  return useMutation({
    mutationFn: (payload: VerifyWorkerPasscodePayload) =>
      verifyWorkerPasscode(payload),
    onSuccess: () => navigate({ to: '/existingAccountCheck' }),
    onError: error => {
      const genericAlertInfo: Alert = {
        title: 'Error.',
        description: 'An unknown error occurred. Please try again later.',
        hideCancel: true,
      }
      void handleHttpException<AlternateErrorResponse>(error, {
        onHttpError: e => {
          switch (e.response?.data?.status) {
            case 401:
              showAlert({
                title: 'Invalid SSN.',
                description:
                  'Please check the last 4 digits of your Social Security Number and try again.',
                hideCancel: true,
              })
              break
            case 429:
              showAlert({
                title: 'Limit reached.',
                description:
                  "You've reached the maximum number of attempts for today. Please try again after 24 hours.",
                hideCancel: true,
              })
              break
            default:
              showAlert(genericAlertInfo)
          }
        },
        onOtherError: () => {
          showAlert(genericAlertInfo)
        },
      })
    },
  })
}
