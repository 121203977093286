import { useQueryClient } from '@tanstack/react-query'

import { useAppNavigate } from '@/hooks/useAppNavigate/useAppNavigate'
import { featureEnabled } from '@/lib/featureFlag'
import { useOrgConfig } from '@/queries/organizations'
import { workerInfoQueryOptions } from '@/queryOptions/workers'
import { useAlertStore } from '@/store/alert'
import { useCreateAccountFormState } from '@/store/createAccountForm'

export const useLoginOTPSuccess = () => {
  const queryClient = useQueryClient()
  const navigate = useAppNavigate()
  const setCreateAccountFormState = useCreateAccountFormState(
    state => state.setCreateAccountFormState
  )
  const { data: orgConfig } = useOrgConfig()
  const showAlert = useAlertStore(state => state.showAlert)

  const handleLoginSuccess = async () => {
    //If the user went back and logged in again we should refetch additional info
    await queryClient.invalidateQueries({
      queryKey: workerInfoQueryOptions.queryKey,
    })

    try {
      const { address, dob, phone, email } = await queryClient.fetchQuery(
        workerInfoQueryOptions
      )

      //I know what you're thinking, why are you syncing react query state into another state manager??
      //Well, this data is going to be used as form values, and we need to keep it separate from react query so the user can update it.
      //Forms are after all an exception to the react query rule of syncing state to other state managers: https://tkdodo.eu/blog/react-query-and-forms#the-simple-approach
      setCreateAccountFormState({
        address: {
          streetLine: address?.address_1 || '',
          secondary: address?.address_2,
          city: address?.city,
          state: address?.state,
          zipcode: address?.zip,
        },
        date_of_birth: dob,
        phone,
        email,
      })

      const directOnboardingFeatureEnabled = featureEnabled(
        'VITE_DIRECT_ONBOARDING'
      )

      if (
        directOnboardingFeatureEnabled &&
        orgConfig?.steps?.includes('WORKER_PASSCODE')
      ) {
        return navigate({ to: '/workerPasscode' })
      }

      return navigate({ to: '/existingAccountCheck' })
    } catch (error) {
      showAlert({
        title: 'Failed to get user info.',
        description:
          'There was an issue getting your information. Please try again later.',
        hideCancel: true,
      })
    }
  }

  return handleLoginSuccess
}
