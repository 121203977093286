import { createFileRoute } from '@tanstack/react-router'

import { redirect } from '@/lib/appRedirect/appRedirect'
import { featureEnabled } from '@/lib/featureFlag'
import { getExistingAccountQueryOptions } from '@/queryOptions/account'
import { orgConfigQueryOptions } from '@/queryOptions/organizations'

export const Route = createFileRoute('/_initialized/existingAccountCheck')({
  loaderDeps: ({ search: { org_id } }) => ({ org_id }),
  loader: async ({ context: { queryClient }, deps: { org_id } }) => {
    if (!featureEnabled('VITE_DIRECT_ONBOARDING')) {
      redirect({ to: '/verifyAccountInfo', throw: true })
    }

    const { direct_account_exists, wallet_account_exists } =
      await queryClient.ensureQueryData(getExistingAccountQueryOptions)

    const orgConfig = queryClient.getQueryData(
      orgConfigQueryOptions(org_id!).queryKey
    )

    if (!direct_account_exists && !wallet_account_exists) {
      if (
        featureEnabled('VITE_DIRECT_ONBOARDING') &&
        orgConfig?.enabled_account_types?.includes('DIRECT')
      ) {
        redirect({ to: '/accountTypeSelection', throw: true })
      }

      redirect({ to: '/verifyAccountInfo', throw: true })
    }
  },
})
