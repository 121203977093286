import { queryOptions } from '@tanstack/react-query'

import {
  getInternalOrganization,
  getOrganizationConfig,
  getOrganizationFees,
} from '@/api/organizations/organizations'
import { featureEnabled } from '@/lib/featureFlag'

export const ORG_QUERY_KEY = 'ORG_QUERY_KEY'

export const orgQueryOptions = (org_id: string) =>
  queryOptions({
    queryKey: [ORG_QUERY_KEY, org_id],
    queryFn: async () => {
      const response = await getInternalOrganization(org_id)
      const responseData = response.data
      let integrationPartnerDisplayName =
        responseData?.payroll_settings?.display_name || 'Branch'

      if (integrationPartnerDisplayName.toLowerCase() === 'none') {
        integrationPartnerDisplayName = 'Branch'
      }

      return {
        ...(response.data || {}),
        app_identifier: response.data?.settings?.app_identifier || 'Branch',
        integrationPartnerDisplayName,
      }
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })

export const ORG_FEES_QUERY_KEY = 'ORG_FEES_QUERY_KEY'
export const orgFeesQueryOptions = queryOptions({
  queryKey: [ORG_FEES_QUERY_KEY],
  queryFn: async () => {
    const response = await getOrganizationFees()
    return response.data
  },
  staleTime: Infinity,
  gcTime: Infinity,
})

export const ORG_CONFIG_QUERY_KEY = 'ORG_CONFIG_QUERY_KEY'
export const orgConfigQueryOptions = (org_id: string) =>
  queryOptions({
    queryKey: [ORG_CONFIG_QUERY_KEY, org_id],
    queryFn: async () => {
      //TODO: once this is removed we can remove all optional chaining on the returned data
      if (!featureEnabled('VITE_DIRECT_ONBOARDING')) {
        return null
      }
      const response = await getOrganizationConfig(org_id)
      return response.data
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })
