import { http } from '../http'
import {
  GetExternalOrganizationPayload,
  GetOrgConfigResponse,
  GetOrgFeesResponse,
  Organization,
  VerifyOrgPasscodePayload,
  VerifyOrgPasscodeResponse,
} from './types'

export const getExternalOrganization = ({
  external_org_id,
  type,
}: GetExternalOrganizationPayload) =>
  http.get<Organization>(`/v1/${type}/organizations/${external_org_id}`)

export const getInternalOrganization = (org_id: string) =>
  http.get<Organization>(`/v1/organizations/${org_id}`)

export const verifyOrgPasscode = (payload: VerifyOrgPasscodePayload) =>
  http.put<VerifyOrgPasscodeResponse>(`/v1/verifications/org_passcode`, payload)

export const getOrganizationFees = () =>
  http.get<GetOrgFeesResponse>(`/v1/organization/fees`)

export const getOrganizationConfig = (org_id: string) =>
  http.get<GetOrgConfigResponse>(`/v1/organizations/${org_id}/config`)
