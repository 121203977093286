import { isPossiblePhoneNumber } from '@branch-messenger/willow-ui'
import * as yup from 'yup'

export const workerLookupFormSchema = yup
  .object()
  .shape({
    phoneNumber: yup.string().test({
      name: 'Phone is valid',
      message: 'Please enter a valid phone number',
      test: value => (value ? isPossiblePhoneNumber(value) : true),
    }),
    email: yup.string().email('Please enter a valid email address'),
  })
  .test({
    name: 'phoneOrEmail',
    message: 'Please enter a valid phone number or email address',
    test: function (value) {
      return !!(value.phoneNumber || value.email)
    },
  })

export type WorkerLookupFormSchema = yup.InferType<
  typeof workerLookupFormSchema
>
