import { RouteError } from '@/errors/RouteError'
import { orgConfigQueryOptions } from '@/queryOptions/organizations'

import { HandleAccountTypeSearchParamProps } from './types/home'

export const handleAccountTypeSearchParam = async ({
  account_type,
  queryClient,
  orgId,
}: HandleAccountTypeSearchParamProps) => {
  const orgConfig = await queryClient.ensureQueryData(
    orgConfigQueryOptions(orgId)
  )
  const enabledAccountTypes = orgConfig?.enabled_account_types || []
  const walletEnabled =
    enabledAccountTypes.includes('WALLET_ESSENTIAL') ||
    enabledAccountTypes.includes('WALLET_PREMIER')
  const directEnabled = enabledAccountTypes.includes('DIRECT')

  if (
    (account_type === 'DIRECT' && !directEnabled) ||
    (account_type === 'WALLET' && !walletEnabled)
  ) {
    const requestedAccountType =
      account_type === 'WALLET' ? 'Branch Wallet' : 'Branch Direct'
    throw new RouteError(
      `This org does not have ${requestedAccountType} accounts enabled. Please reach out to your manager for assistance.`,
      'Unsupported Account Type'
    )
  }
}
