import { queryOptions } from '@tanstack/react-query'

import { getExistingAccount } from '@/api/accounts'

export const GET_EXISTING_ACCOUNT_QUERY_KEY = 'GET_EXISTING_ACCOUNT_QUERY_KEY'
export const getExistingAccountQueryOptions = queryOptions({
  queryKey: [GET_EXISTING_ACCOUNT_QUERY_KEY],
  queryFn: async () => {
    const res = await getExistingAccount()
    return res.data
  },
  staleTime: Infinity,
  gcTime: Infinity,
})
