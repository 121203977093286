import {
  Button,
  ButtonLink,
  ButtonLinkProps,
  ButtonProps,
} from '@branch-messenger/willow-ui'
import { forwardRef } from 'react'

export const ContentCardButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }, ref) => <Button ref={ref} {...props} size="lg" />
)

ContentCardButton.displayName = 'ContentCardButton'

export const ContentCardButtonLink = forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps
>(({ ...props }, ref) => <ButtonLink ref={ref} {...props} size="lg" />)

ContentCardButtonLink.displayName = 'ContentCardButtonLink'
