import { create } from 'zustand'

import { NavigationHistoryStore } from './navigationHistory.types'

export const useNavigationHistoryStore = create<NavigationHistoryStore>(
  (set, get) => ({
    history: [],
    addToHistory: navigateOptions =>
      set(state => ({
        history: [...state.history, navigateOptions],
      })),
    clearHistory: () => set({ history: [] }),
    popHistory: () =>
      set(state => {
        const newHistory = [...state.history]
        newHistory.pop()
        return { history: newHistory }
      }),

    getPreviousState: () => {
      const currentState = get().history
      return currentState.length > 1
        ? currentState[currentState.length - 2]
        : undefined
    },
  })
)
