import { createFileRoute, retainSearchParams } from '@tanstack/react-router'

import {
  directTermsQueryOptions,
  essentialTermsQueryOptions,
  userTermsQueryOptions,
} from '@/queryOptions/terms'

export const Route = createFileRoute('/_initialized/terms/agreements/$step')({
  loaderDeps: ({ search: { agreementType } }) => ({ agreementType }),
  loader: async ({ deps: { agreementType }, context: { queryClient } }) => {
    switch (agreementType) {
      case 'essential':
        return queryClient.ensureQueryData(essentialTermsQueryOptions)
      case 'user':
        return queryClient.ensureQueryData(userTermsQueryOptions)
      case 'direct':
        return queryClient.ensureQueryData(directTermsQueryOptions)
    }
  },
  search: {
    middlewares: [retainSearchParams(['agreementType'])],
  },
})
