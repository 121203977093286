/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as InitializedImport } from './routes/_initialized'
import { Route as SplatImport } from './routes/$'
import { Route as IndexImport } from './routes/index'
import { Route as InitializedWorkerLookupImport } from './routes/_initialized/workerLookup'
import { Route as InitializedVerifyAccountInfoImport } from './routes/_initialized/verifyAccountInfo'
import { Route as InitializedVerificationSelectionImport } from './routes/_initialized/verificationSelection'
import { Route as InitializedTermsImport } from './routes/_initialized/terms'
import { Route as InitializedPhoneVerificationImport } from './routes/_initialized/phoneVerification'
import { Route as InitializedOtpVerificationImport } from './routes/_initialized/otpVerification'
import { Route as InitializedLinkDirectAccountImport } from './routes/_initialized/linkDirectAccount'
import { Route as InitializedLinkAccountImport } from './routes/_initialized/linkAccount'
import { Route as InitializedExistingAccountCheckImport } from './routes/_initialized/existingAccountCheck'
import { Route as InitializedDirectDepositAuthorizationImport } from './routes/_initialized/directDepositAuthorization'
import { Route as InitializedDirectAccountSetupImport } from './routes/_initialized/directAccountSetup'
import { Route as InitializedConfirmOrderImport } from './routes/_initialized/confirmOrder'
import { Route as InitializedCardInfoImport } from './routes/_initialized/cardInfo'
import { Route as InitializedAppQrCodeImport } from './routes/_initialized/appQrCode'
import { Route as InitializedAccountTypeSelectionImport } from './routes/_initialized/accountTypeSelection'
import { Route as InitializedAccountCreatedImport } from './routes/_initialized/accountCreated'
import { Route as InitializedOtpVerificationIndexImport } from './routes/_initialized/otpVerification/index'
import { Route as InitializedTermsAgreementsStepImport } from './routes/_initialized/terms/agreements.$step'

// Create Virtual Routes

const StoragePermissionsAcknowledgementLazyImport = createFileRoute(
  '/storagePermissionsAcknowledgement',
)()
const AcceptStoragePermissionsLazyImport = createFileRoute(
  '/acceptStoragePermissions',
)()
const InitializedWorkerPasscodeLazyImport = createFileRoute(
  '/_initialized/workerPasscode',
)()
const InitializedWhatIsBranchDirectLazyImport = createFileRoute(
  '/_initialized/whatIsBranchDirect',
)()
const InitializedWhatIsBranchLazyImport = createFileRoute(
  '/_initialized/whatIsBranch',
)()
const InitializedWelcomeLazyImport = createFileRoute('/_initialized/welcome')()
const InitializedPasscodeLazyImport = createFileRoute(
  '/_initialized/passcode',
)()
const InitializedOrgPasscodeLazyImport = createFileRoute(
  '/_initialized/orgPasscode',
)()
const InitializedOrderConfirmedLazyImport = createFileRoute(
  '/_initialized/orderConfirmed',
)()
const InitializedMissingAccountInfoLazyImport = createFileRoute(
  '/_initialized/missingAccountInfo',
)()
const InitializedLoginLazyImport = createFileRoute('/_initialized/login')()
const InitializedLinkedAccountSignInLazyImport = createFileRoute(
  '/_initialized/linkedAccountSignIn',
)()
const InitializedDownloadAppLazyImport = createFileRoute(
  '/_initialized/downloadApp',
)()
const InitializedDateOfBirthLazyImport = createFileRoute(
  '/_initialized/dateOfBirth',
)()
const InitializedConfirmPasscodeLazyImport = createFileRoute(
  '/_initialized/confirmPasscode',
)()
const InitializedTermsIndexLazyImport = createFileRoute(
  '/_initialized/terms/',
)()
const InitializedOtpVerificationWalletAccountLazyImport = createFileRoute(
  '/_initialized/otpVerification/walletAccount',
)()
const InitializedOtpVerificationDirectAccountLazyImport = createFileRoute(
  '/_initialized/otpVerification/directAccount',
)()
const InitializedAddressVerificationResultsLazyImport = createFileRoute(
  '/_initialized/address/verificationResults',
)()
const InitializedAddressPoBoxErrorLazyImport = createFileRoute(
  '/_initialized/address/poBoxError',
)()
const InitializedAddressManualAddressFormLazyImport = createFileRoute(
  '/_initialized/address/manualAddressForm',
)()
const InitializedAddressAddressLookupLazyImport = createFileRoute(
  '/_initialized/address/addressLookup',
)()

// Create/Update Routes

const StoragePermissionsAcknowledgementLazyRoute =
  StoragePermissionsAcknowledgementLazyImport.update({
    id: '/storagePermissionsAcknowledgement',
    path: '/storagePermissionsAcknowledgement',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/storagePermissionsAcknowledgement.lazy').then(
      (d) => d.Route,
    ),
  )

const AcceptStoragePermissionsLazyRoute =
  AcceptStoragePermissionsLazyImport.update({
    id: '/acceptStoragePermissions',
    path: '/acceptStoragePermissions',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/acceptStoragePermissions.lazy').then((d) => d.Route),
  )

const InitializedRoute = InitializedImport.update({
  id: '/_initialized',
  getParentRoute: () => rootRoute,
} as any)

const SplatRoute = SplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const InitializedWorkerPasscodeLazyRoute =
  InitializedWorkerPasscodeLazyImport.update({
    id: '/workerPasscode',
    path: '/workerPasscode',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/workerPasscode.lazy').then((d) => d.Route),
  )

const InitializedWhatIsBranchDirectLazyRoute =
  InitializedWhatIsBranchDirectLazyImport.update({
    id: '/whatIsBranchDirect',
    path: '/whatIsBranchDirect',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/whatIsBranchDirect.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedWhatIsBranchLazyRoute =
  InitializedWhatIsBranchLazyImport.update({
    id: '/whatIsBranch',
    path: '/whatIsBranch',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/whatIsBranch.lazy').then((d) => d.Route),
  )

const InitializedWelcomeLazyRoute = InitializedWelcomeLazyImport.update({
  id: '/welcome',
  path: '/welcome',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/welcome.lazy').then((d) => d.Route),
)

const InitializedPasscodeLazyRoute = InitializedPasscodeLazyImport.update({
  id: '/passcode',
  path: '/passcode',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/passcode.lazy').then((d) => d.Route),
)

const InitializedOrgPasscodeLazyRoute = InitializedOrgPasscodeLazyImport.update(
  {
    id: '/orgPasscode',
    path: '/orgPasscode',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/orgPasscode.lazy').then((d) => d.Route),
)

const InitializedOrderConfirmedLazyRoute =
  InitializedOrderConfirmedLazyImport.update({
    id: '/orderConfirmed',
    path: '/orderConfirmed',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/orderConfirmed.lazy').then((d) => d.Route),
  )

const InitializedMissingAccountInfoLazyRoute =
  InitializedMissingAccountInfoLazyImport.update({
    id: '/missingAccountInfo',
    path: '/missingAccountInfo',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/missingAccountInfo.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedLoginLazyRoute = InitializedLoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/login.lazy').then((d) => d.Route),
)

const InitializedLinkedAccountSignInLazyRoute =
  InitializedLinkedAccountSignInLazyImport.update({
    id: '/linkedAccountSignIn',
    path: '/linkedAccountSignIn',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/linkedAccountSignIn.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedDownloadAppLazyRoute = InitializedDownloadAppLazyImport.update(
  {
    id: '/downloadApp',
    path: '/downloadApp',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/downloadApp.lazy').then((d) => d.Route),
)

const InitializedDateOfBirthLazyRoute = InitializedDateOfBirthLazyImport.update(
  {
    id: '/dateOfBirth',
    path: '/dateOfBirth',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/dateOfBirth.lazy').then((d) => d.Route),
)

const InitializedConfirmPasscodeLazyRoute =
  InitializedConfirmPasscodeLazyImport.update({
    id: '/confirmPasscode',
    path: '/confirmPasscode',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/confirmPasscode.lazy').then((d) => d.Route),
  )

const InitializedWorkerLookupRoute = InitializedWorkerLookupImport.update({
  id: '/workerLookup',
  path: '/workerLookup',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedVerifyAccountInfoRoute =
  InitializedVerifyAccountInfoImport.update({
    id: '/verifyAccountInfo',
    path: '/verifyAccountInfo',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/verifyAccountInfo.lazy').then((d) => d.Route),
  )

const InitializedVerificationSelectionRoute =
  InitializedVerificationSelectionImport.update({
    id: '/verificationSelection',
    path: '/verificationSelection',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/verificationSelection.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedTermsRoute = InitializedTermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedPhoneVerificationRoute =
  InitializedPhoneVerificationImport.update({
    id: '/phoneVerification',
    path: '/phoneVerification',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/phoneVerification.lazy').then((d) => d.Route),
  )

const InitializedOtpVerificationRoute = InitializedOtpVerificationImport.update(
  {
    id: '/otpVerification',
    path: '/otpVerification',
    getParentRoute: () => InitializedRoute,
  } as any,
)

const InitializedLinkDirectAccountRoute =
  InitializedLinkDirectAccountImport.update({
    id: '/linkDirectAccount',
    path: '/linkDirectAccount',
    getParentRoute: () => InitializedRoute,
  } as any)

const InitializedLinkAccountRoute = InitializedLinkAccountImport.update({
  id: '/linkAccount',
  path: '/linkAccount',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/linkAccount.lazy').then((d) => d.Route),
)

const InitializedExistingAccountCheckRoute =
  InitializedExistingAccountCheckImport.update({
    id: '/existingAccountCheck',
    path: '/existingAccountCheck',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/existingAccountCheck.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedDirectDepositAuthorizationRoute =
  InitializedDirectDepositAuthorizationImport.update({
    id: '/directDepositAuthorization',
    path: '/directDepositAuthorization',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/directDepositAuthorization.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedDirectAccountSetupRoute =
  InitializedDirectAccountSetupImport.update({
    id: '/directAccountSetup',
    path: '/directAccountSetup',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/directAccountSetup.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedConfirmOrderRoute = InitializedConfirmOrderImport.update({
  id: '/confirmOrder',
  path: '/confirmOrder',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedCardInfoRoute = InitializedCardInfoImport.update({
  id: '/cardInfo',
  path: '/cardInfo',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedAppQrCodeRoute = InitializedAppQrCodeImport.update({
  id: '/appQrCode',
  path: '/appQrCode',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedAccountTypeSelectionRoute =
  InitializedAccountTypeSelectionImport.update({
    id: '/accountTypeSelection',
    path: '/accountTypeSelection',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/accountTypeSelection.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAccountCreatedRoute = InitializedAccountCreatedImport.update({
  id: '/accountCreated',
  path: '/accountCreated',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/accountCreated.lazy').then((d) => d.Route),
)

const InitializedTermsIndexLazyRoute = InitializedTermsIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => InitializedTermsRoute,
} as any).lazy(() =>
  import('./routes/_initialized/terms/index.lazy').then((d) => d.Route),
)

const InitializedOtpVerificationIndexRoute =
  InitializedOtpVerificationIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => InitializedOtpVerificationRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/otpVerification/index.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedOtpVerificationWalletAccountLazyRoute =
  InitializedOtpVerificationWalletAccountLazyImport.update({
    id: '/walletAccount',
    path: '/walletAccount',
    getParentRoute: () => InitializedOtpVerificationRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/otpVerification/walletAccount.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedOtpVerificationDirectAccountLazyRoute =
  InitializedOtpVerificationDirectAccountLazyImport.update({
    id: '/directAccount',
    path: '/directAccount',
    getParentRoute: () => InitializedOtpVerificationRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/otpVerification/directAccount.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressVerificationResultsLazyRoute =
  InitializedAddressVerificationResultsLazyImport.update({
    id: '/address/verificationResults',
    path: '/address/verificationResults',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/verificationResults.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressPoBoxErrorLazyRoute =
  InitializedAddressPoBoxErrorLazyImport.update({
    id: '/address/poBoxError',
    path: '/address/poBoxError',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/poBoxError.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressManualAddressFormLazyRoute =
  InitializedAddressManualAddressFormLazyImport.update({
    id: '/address/manualAddressForm',
    path: '/address/manualAddressForm',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/manualAddressForm.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressAddressLookupLazyRoute =
  InitializedAddressAddressLookupLazyImport.update({
    id: '/address/addressLookup',
    path: '/address/addressLookup',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/addressLookup.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedTermsAgreementsStepRoute =
  InitializedTermsAgreementsStepImport.update({
    id: '/agreements/$step',
    path: '/agreements/$step',
    getParentRoute: () => InitializedTermsRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/terms/agreements.$step.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/$': {
      id: '/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof SplatImport
      parentRoute: typeof rootRoute
    }
    '/_initialized': {
      id: '/_initialized'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof InitializedImport
      parentRoute: typeof rootRoute
    }
    '/acceptStoragePermissions': {
      id: '/acceptStoragePermissions'
      path: '/acceptStoragePermissions'
      fullPath: '/acceptStoragePermissions'
      preLoaderRoute: typeof AcceptStoragePermissionsLazyImport
      parentRoute: typeof rootRoute
    }
    '/storagePermissionsAcknowledgement': {
      id: '/storagePermissionsAcknowledgement'
      path: '/storagePermissionsAcknowledgement'
      fullPath: '/storagePermissionsAcknowledgement'
      preLoaderRoute: typeof StoragePermissionsAcknowledgementLazyImport
      parentRoute: typeof rootRoute
    }
    '/_initialized/accountCreated': {
      id: '/_initialized/accountCreated'
      path: '/accountCreated'
      fullPath: '/accountCreated'
      preLoaderRoute: typeof InitializedAccountCreatedImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/accountTypeSelection': {
      id: '/_initialized/accountTypeSelection'
      path: '/accountTypeSelection'
      fullPath: '/accountTypeSelection'
      preLoaderRoute: typeof InitializedAccountTypeSelectionImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/appQrCode': {
      id: '/_initialized/appQrCode'
      path: '/appQrCode'
      fullPath: '/appQrCode'
      preLoaderRoute: typeof InitializedAppQrCodeImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/cardInfo': {
      id: '/_initialized/cardInfo'
      path: '/cardInfo'
      fullPath: '/cardInfo'
      preLoaderRoute: typeof InitializedCardInfoImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/confirmOrder': {
      id: '/_initialized/confirmOrder'
      path: '/confirmOrder'
      fullPath: '/confirmOrder'
      preLoaderRoute: typeof InitializedConfirmOrderImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/directAccountSetup': {
      id: '/_initialized/directAccountSetup'
      path: '/directAccountSetup'
      fullPath: '/directAccountSetup'
      preLoaderRoute: typeof InitializedDirectAccountSetupImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/directDepositAuthorization': {
      id: '/_initialized/directDepositAuthorization'
      path: '/directDepositAuthorization'
      fullPath: '/directDepositAuthorization'
      preLoaderRoute: typeof InitializedDirectDepositAuthorizationImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/existingAccountCheck': {
      id: '/_initialized/existingAccountCheck'
      path: '/existingAccountCheck'
      fullPath: '/existingAccountCheck'
      preLoaderRoute: typeof InitializedExistingAccountCheckImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/linkAccount': {
      id: '/_initialized/linkAccount'
      path: '/linkAccount'
      fullPath: '/linkAccount'
      preLoaderRoute: typeof InitializedLinkAccountImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/linkDirectAccount': {
      id: '/_initialized/linkDirectAccount'
      path: '/linkDirectAccount'
      fullPath: '/linkDirectAccount'
      preLoaderRoute: typeof InitializedLinkDirectAccountImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/otpVerification': {
      id: '/_initialized/otpVerification'
      path: '/otpVerification'
      fullPath: '/otpVerification'
      preLoaderRoute: typeof InitializedOtpVerificationImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/phoneVerification': {
      id: '/_initialized/phoneVerification'
      path: '/phoneVerification'
      fullPath: '/phoneVerification'
      preLoaderRoute: typeof InitializedPhoneVerificationImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/terms': {
      id: '/_initialized/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof InitializedTermsImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/verificationSelection': {
      id: '/_initialized/verificationSelection'
      path: '/verificationSelection'
      fullPath: '/verificationSelection'
      preLoaderRoute: typeof InitializedVerificationSelectionImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/verifyAccountInfo': {
      id: '/_initialized/verifyAccountInfo'
      path: '/verifyAccountInfo'
      fullPath: '/verifyAccountInfo'
      preLoaderRoute: typeof InitializedVerifyAccountInfoImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/workerLookup': {
      id: '/_initialized/workerLookup'
      path: '/workerLookup'
      fullPath: '/workerLookup'
      preLoaderRoute: typeof InitializedWorkerLookupImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/confirmPasscode': {
      id: '/_initialized/confirmPasscode'
      path: '/confirmPasscode'
      fullPath: '/confirmPasscode'
      preLoaderRoute: typeof InitializedConfirmPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/dateOfBirth': {
      id: '/_initialized/dateOfBirth'
      path: '/dateOfBirth'
      fullPath: '/dateOfBirth'
      preLoaderRoute: typeof InitializedDateOfBirthLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/downloadApp': {
      id: '/_initialized/downloadApp'
      path: '/downloadApp'
      fullPath: '/downloadApp'
      preLoaderRoute: typeof InitializedDownloadAppLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/linkedAccountSignIn': {
      id: '/_initialized/linkedAccountSignIn'
      path: '/linkedAccountSignIn'
      fullPath: '/linkedAccountSignIn'
      preLoaderRoute: typeof InitializedLinkedAccountSignInLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/login': {
      id: '/_initialized/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof InitializedLoginLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/missingAccountInfo': {
      id: '/_initialized/missingAccountInfo'
      path: '/missingAccountInfo'
      fullPath: '/missingAccountInfo'
      preLoaderRoute: typeof InitializedMissingAccountInfoLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/orderConfirmed': {
      id: '/_initialized/orderConfirmed'
      path: '/orderConfirmed'
      fullPath: '/orderConfirmed'
      preLoaderRoute: typeof InitializedOrderConfirmedLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/orgPasscode': {
      id: '/_initialized/orgPasscode'
      path: '/orgPasscode'
      fullPath: '/orgPasscode'
      preLoaderRoute: typeof InitializedOrgPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/passcode': {
      id: '/_initialized/passcode'
      path: '/passcode'
      fullPath: '/passcode'
      preLoaderRoute: typeof InitializedPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/welcome': {
      id: '/_initialized/welcome'
      path: '/welcome'
      fullPath: '/welcome'
      preLoaderRoute: typeof InitializedWelcomeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/whatIsBranch': {
      id: '/_initialized/whatIsBranch'
      path: '/whatIsBranch'
      fullPath: '/whatIsBranch'
      preLoaderRoute: typeof InitializedWhatIsBranchLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/whatIsBranchDirect': {
      id: '/_initialized/whatIsBranchDirect'
      path: '/whatIsBranchDirect'
      fullPath: '/whatIsBranchDirect'
      preLoaderRoute: typeof InitializedWhatIsBranchDirectLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/workerPasscode': {
      id: '/_initialized/workerPasscode'
      path: '/workerPasscode'
      fullPath: '/workerPasscode'
      preLoaderRoute: typeof InitializedWorkerPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/addressLookup': {
      id: '/_initialized/address/addressLookup'
      path: '/address/addressLookup'
      fullPath: '/address/addressLookup'
      preLoaderRoute: typeof InitializedAddressAddressLookupLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/manualAddressForm': {
      id: '/_initialized/address/manualAddressForm'
      path: '/address/manualAddressForm'
      fullPath: '/address/manualAddressForm'
      preLoaderRoute: typeof InitializedAddressManualAddressFormLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/poBoxError': {
      id: '/_initialized/address/poBoxError'
      path: '/address/poBoxError'
      fullPath: '/address/poBoxError'
      preLoaderRoute: typeof InitializedAddressPoBoxErrorLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/verificationResults': {
      id: '/_initialized/address/verificationResults'
      path: '/address/verificationResults'
      fullPath: '/address/verificationResults'
      preLoaderRoute: typeof InitializedAddressVerificationResultsLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/otpVerification/directAccount': {
      id: '/_initialized/otpVerification/directAccount'
      path: '/directAccount'
      fullPath: '/otpVerification/directAccount'
      preLoaderRoute: typeof InitializedOtpVerificationDirectAccountLazyImport
      parentRoute: typeof InitializedOtpVerificationImport
    }
    '/_initialized/otpVerification/walletAccount': {
      id: '/_initialized/otpVerification/walletAccount'
      path: '/walletAccount'
      fullPath: '/otpVerification/walletAccount'
      preLoaderRoute: typeof InitializedOtpVerificationWalletAccountLazyImport
      parentRoute: typeof InitializedOtpVerificationImport
    }
    '/_initialized/otpVerification/': {
      id: '/_initialized/otpVerification/'
      path: '/'
      fullPath: '/otpVerification/'
      preLoaderRoute: typeof InitializedOtpVerificationIndexImport
      parentRoute: typeof InitializedOtpVerificationImport
    }
    '/_initialized/terms/': {
      id: '/_initialized/terms/'
      path: '/'
      fullPath: '/terms/'
      preLoaderRoute: typeof InitializedTermsIndexLazyImport
      parentRoute: typeof InitializedTermsImport
    }
    '/_initialized/terms/agreements/$step': {
      id: '/_initialized/terms/agreements/$step'
      path: '/agreements/$step'
      fullPath: '/terms/agreements/$step'
      preLoaderRoute: typeof InitializedTermsAgreementsStepImport
      parentRoute: typeof InitializedTermsImport
    }
  }
}

// Create and export the route tree

interface InitializedOtpVerificationRouteChildren {
  InitializedOtpVerificationDirectAccountLazyRoute: typeof InitializedOtpVerificationDirectAccountLazyRoute
  InitializedOtpVerificationWalletAccountLazyRoute: typeof InitializedOtpVerificationWalletAccountLazyRoute
  InitializedOtpVerificationIndexRoute: typeof InitializedOtpVerificationIndexRoute
}

const InitializedOtpVerificationRouteChildren: InitializedOtpVerificationRouteChildren =
  {
    InitializedOtpVerificationDirectAccountLazyRoute:
      InitializedOtpVerificationDirectAccountLazyRoute,
    InitializedOtpVerificationWalletAccountLazyRoute:
      InitializedOtpVerificationWalletAccountLazyRoute,
    InitializedOtpVerificationIndexRoute: InitializedOtpVerificationIndexRoute,
  }

const InitializedOtpVerificationRouteWithChildren =
  InitializedOtpVerificationRoute._addFileChildren(
    InitializedOtpVerificationRouteChildren,
  )

interface InitializedTermsRouteChildren {
  InitializedTermsIndexLazyRoute: typeof InitializedTermsIndexLazyRoute
  InitializedTermsAgreementsStepRoute: typeof InitializedTermsAgreementsStepRoute
}

const InitializedTermsRouteChildren: InitializedTermsRouteChildren = {
  InitializedTermsIndexLazyRoute: InitializedTermsIndexLazyRoute,
  InitializedTermsAgreementsStepRoute: InitializedTermsAgreementsStepRoute,
}

const InitializedTermsRouteWithChildren =
  InitializedTermsRoute._addFileChildren(InitializedTermsRouteChildren)

interface InitializedRouteChildren {
  InitializedAccountCreatedRoute: typeof InitializedAccountCreatedRoute
  InitializedAccountTypeSelectionRoute: typeof InitializedAccountTypeSelectionRoute
  InitializedAppQrCodeRoute: typeof InitializedAppQrCodeRoute
  InitializedCardInfoRoute: typeof InitializedCardInfoRoute
  InitializedConfirmOrderRoute: typeof InitializedConfirmOrderRoute
  InitializedDirectAccountSetupRoute: typeof InitializedDirectAccountSetupRoute
  InitializedDirectDepositAuthorizationRoute: typeof InitializedDirectDepositAuthorizationRoute
  InitializedExistingAccountCheckRoute: typeof InitializedExistingAccountCheckRoute
  InitializedLinkAccountRoute: typeof InitializedLinkAccountRoute
  InitializedLinkDirectAccountRoute: typeof InitializedLinkDirectAccountRoute
  InitializedOtpVerificationRoute: typeof InitializedOtpVerificationRouteWithChildren
  InitializedPhoneVerificationRoute: typeof InitializedPhoneVerificationRoute
  InitializedTermsRoute: typeof InitializedTermsRouteWithChildren
  InitializedVerificationSelectionRoute: typeof InitializedVerificationSelectionRoute
  InitializedVerifyAccountInfoRoute: typeof InitializedVerifyAccountInfoRoute
  InitializedWorkerLookupRoute: typeof InitializedWorkerLookupRoute
  InitializedConfirmPasscodeLazyRoute: typeof InitializedConfirmPasscodeLazyRoute
  InitializedDateOfBirthLazyRoute: typeof InitializedDateOfBirthLazyRoute
  InitializedDownloadAppLazyRoute: typeof InitializedDownloadAppLazyRoute
  InitializedLinkedAccountSignInLazyRoute: typeof InitializedLinkedAccountSignInLazyRoute
  InitializedLoginLazyRoute: typeof InitializedLoginLazyRoute
  InitializedMissingAccountInfoLazyRoute: typeof InitializedMissingAccountInfoLazyRoute
  InitializedOrderConfirmedLazyRoute: typeof InitializedOrderConfirmedLazyRoute
  InitializedOrgPasscodeLazyRoute: typeof InitializedOrgPasscodeLazyRoute
  InitializedPasscodeLazyRoute: typeof InitializedPasscodeLazyRoute
  InitializedWelcomeLazyRoute: typeof InitializedWelcomeLazyRoute
  InitializedWhatIsBranchLazyRoute: typeof InitializedWhatIsBranchLazyRoute
  InitializedWhatIsBranchDirectLazyRoute: typeof InitializedWhatIsBranchDirectLazyRoute
  InitializedWorkerPasscodeLazyRoute: typeof InitializedWorkerPasscodeLazyRoute
  InitializedAddressAddressLookupLazyRoute: typeof InitializedAddressAddressLookupLazyRoute
  InitializedAddressManualAddressFormLazyRoute: typeof InitializedAddressManualAddressFormLazyRoute
  InitializedAddressPoBoxErrorLazyRoute: typeof InitializedAddressPoBoxErrorLazyRoute
  InitializedAddressVerificationResultsLazyRoute: typeof InitializedAddressVerificationResultsLazyRoute
}

const InitializedRouteChildren: InitializedRouteChildren = {
  InitializedAccountCreatedRoute: InitializedAccountCreatedRoute,
  InitializedAccountTypeSelectionRoute: InitializedAccountTypeSelectionRoute,
  InitializedAppQrCodeRoute: InitializedAppQrCodeRoute,
  InitializedCardInfoRoute: InitializedCardInfoRoute,
  InitializedConfirmOrderRoute: InitializedConfirmOrderRoute,
  InitializedDirectAccountSetupRoute: InitializedDirectAccountSetupRoute,
  InitializedDirectDepositAuthorizationRoute:
    InitializedDirectDepositAuthorizationRoute,
  InitializedExistingAccountCheckRoute: InitializedExistingAccountCheckRoute,
  InitializedLinkAccountRoute: InitializedLinkAccountRoute,
  InitializedLinkDirectAccountRoute: InitializedLinkDirectAccountRoute,
  InitializedOtpVerificationRoute: InitializedOtpVerificationRouteWithChildren,
  InitializedPhoneVerificationRoute: InitializedPhoneVerificationRoute,
  InitializedTermsRoute: InitializedTermsRouteWithChildren,
  InitializedVerificationSelectionRoute: InitializedVerificationSelectionRoute,
  InitializedVerifyAccountInfoRoute: InitializedVerifyAccountInfoRoute,
  InitializedWorkerLookupRoute: InitializedWorkerLookupRoute,
  InitializedConfirmPasscodeLazyRoute: InitializedConfirmPasscodeLazyRoute,
  InitializedDateOfBirthLazyRoute: InitializedDateOfBirthLazyRoute,
  InitializedDownloadAppLazyRoute: InitializedDownloadAppLazyRoute,
  InitializedLinkedAccountSignInLazyRoute:
    InitializedLinkedAccountSignInLazyRoute,
  InitializedLoginLazyRoute: InitializedLoginLazyRoute,
  InitializedMissingAccountInfoLazyRoute:
    InitializedMissingAccountInfoLazyRoute,
  InitializedOrderConfirmedLazyRoute: InitializedOrderConfirmedLazyRoute,
  InitializedOrgPasscodeLazyRoute: InitializedOrgPasscodeLazyRoute,
  InitializedPasscodeLazyRoute: InitializedPasscodeLazyRoute,
  InitializedWelcomeLazyRoute: InitializedWelcomeLazyRoute,
  InitializedWhatIsBranchLazyRoute: InitializedWhatIsBranchLazyRoute,
  InitializedWhatIsBranchDirectLazyRoute:
    InitializedWhatIsBranchDirectLazyRoute,
  InitializedWorkerPasscodeLazyRoute: InitializedWorkerPasscodeLazyRoute,
  InitializedAddressAddressLookupLazyRoute:
    InitializedAddressAddressLookupLazyRoute,
  InitializedAddressManualAddressFormLazyRoute:
    InitializedAddressManualAddressFormLazyRoute,
  InitializedAddressPoBoxErrorLazyRoute: InitializedAddressPoBoxErrorLazyRoute,
  InitializedAddressVerificationResultsLazyRoute:
    InitializedAddressVerificationResultsLazyRoute,
}

const InitializedRouteWithChildren = InitializedRoute._addFileChildren(
  InitializedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '': typeof InitializedRouteWithChildren
  '/acceptStoragePermissions': typeof AcceptStoragePermissionsLazyRoute
  '/storagePermissionsAcknowledgement': typeof StoragePermissionsAcknowledgementLazyRoute
  '/accountCreated': typeof InitializedAccountCreatedRoute
  '/accountTypeSelection': typeof InitializedAccountTypeSelectionRoute
  '/appQrCode': typeof InitializedAppQrCodeRoute
  '/cardInfo': typeof InitializedCardInfoRoute
  '/confirmOrder': typeof InitializedConfirmOrderRoute
  '/directAccountSetup': typeof InitializedDirectAccountSetupRoute
  '/directDepositAuthorization': typeof InitializedDirectDepositAuthorizationRoute
  '/existingAccountCheck': typeof InitializedExistingAccountCheckRoute
  '/linkAccount': typeof InitializedLinkAccountRoute
  '/linkDirectAccount': typeof InitializedLinkDirectAccountRoute
  '/otpVerification': typeof InitializedOtpVerificationRouteWithChildren
  '/phoneVerification': typeof InitializedPhoneVerificationRoute
  '/terms': typeof InitializedTermsRouteWithChildren
  '/verificationSelection': typeof InitializedVerificationSelectionRoute
  '/verifyAccountInfo': typeof InitializedVerifyAccountInfoRoute
  '/workerLookup': typeof InitializedWorkerLookupRoute
  '/confirmPasscode': typeof InitializedConfirmPasscodeLazyRoute
  '/dateOfBirth': typeof InitializedDateOfBirthLazyRoute
  '/downloadApp': typeof InitializedDownloadAppLazyRoute
  '/linkedAccountSignIn': typeof InitializedLinkedAccountSignInLazyRoute
  '/login': typeof InitializedLoginLazyRoute
  '/missingAccountInfo': typeof InitializedMissingAccountInfoLazyRoute
  '/orderConfirmed': typeof InitializedOrderConfirmedLazyRoute
  '/orgPasscode': typeof InitializedOrgPasscodeLazyRoute
  '/passcode': typeof InitializedPasscodeLazyRoute
  '/welcome': typeof InitializedWelcomeLazyRoute
  '/whatIsBranch': typeof InitializedWhatIsBranchLazyRoute
  '/whatIsBranchDirect': typeof InitializedWhatIsBranchDirectLazyRoute
  '/workerPasscode': typeof InitializedWorkerPasscodeLazyRoute
  '/address/addressLookup': typeof InitializedAddressAddressLookupLazyRoute
  '/address/manualAddressForm': typeof InitializedAddressManualAddressFormLazyRoute
  '/address/poBoxError': typeof InitializedAddressPoBoxErrorLazyRoute
  '/address/verificationResults': typeof InitializedAddressVerificationResultsLazyRoute
  '/otpVerification/directAccount': typeof InitializedOtpVerificationDirectAccountLazyRoute
  '/otpVerification/walletAccount': typeof InitializedOtpVerificationWalletAccountLazyRoute
  '/otpVerification/': typeof InitializedOtpVerificationIndexRoute
  '/terms/': typeof InitializedTermsIndexLazyRoute
  '/terms/agreements/$step': typeof InitializedTermsAgreementsStepRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '': typeof InitializedRouteWithChildren
  '/acceptStoragePermissions': typeof AcceptStoragePermissionsLazyRoute
  '/storagePermissionsAcknowledgement': typeof StoragePermissionsAcknowledgementLazyRoute
  '/accountCreated': typeof InitializedAccountCreatedRoute
  '/accountTypeSelection': typeof InitializedAccountTypeSelectionRoute
  '/appQrCode': typeof InitializedAppQrCodeRoute
  '/cardInfo': typeof InitializedCardInfoRoute
  '/confirmOrder': typeof InitializedConfirmOrderRoute
  '/directAccountSetup': typeof InitializedDirectAccountSetupRoute
  '/directDepositAuthorization': typeof InitializedDirectDepositAuthorizationRoute
  '/existingAccountCheck': typeof InitializedExistingAccountCheckRoute
  '/linkAccount': typeof InitializedLinkAccountRoute
  '/linkDirectAccount': typeof InitializedLinkDirectAccountRoute
  '/phoneVerification': typeof InitializedPhoneVerificationRoute
  '/verificationSelection': typeof InitializedVerificationSelectionRoute
  '/verifyAccountInfo': typeof InitializedVerifyAccountInfoRoute
  '/workerLookup': typeof InitializedWorkerLookupRoute
  '/confirmPasscode': typeof InitializedConfirmPasscodeLazyRoute
  '/dateOfBirth': typeof InitializedDateOfBirthLazyRoute
  '/downloadApp': typeof InitializedDownloadAppLazyRoute
  '/linkedAccountSignIn': typeof InitializedLinkedAccountSignInLazyRoute
  '/login': typeof InitializedLoginLazyRoute
  '/missingAccountInfo': typeof InitializedMissingAccountInfoLazyRoute
  '/orderConfirmed': typeof InitializedOrderConfirmedLazyRoute
  '/orgPasscode': typeof InitializedOrgPasscodeLazyRoute
  '/passcode': typeof InitializedPasscodeLazyRoute
  '/welcome': typeof InitializedWelcomeLazyRoute
  '/whatIsBranch': typeof InitializedWhatIsBranchLazyRoute
  '/whatIsBranchDirect': typeof InitializedWhatIsBranchDirectLazyRoute
  '/workerPasscode': typeof InitializedWorkerPasscodeLazyRoute
  '/address/addressLookup': typeof InitializedAddressAddressLookupLazyRoute
  '/address/manualAddressForm': typeof InitializedAddressManualAddressFormLazyRoute
  '/address/poBoxError': typeof InitializedAddressPoBoxErrorLazyRoute
  '/address/verificationResults': typeof InitializedAddressVerificationResultsLazyRoute
  '/otpVerification/directAccount': typeof InitializedOtpVerificationDirectAccountLazyRoute
  '/otpVerification/walletAccount': typeof InitializedOtpVerificationWalletAccountLazyRoute
  '/otpVerification': typeof InitializedOtpVerificationIndexRoute
  '/terms': typeof InitializedTermsIndexLazyRoute
  '/terms/agreements/$step': typeof InitializedTermsAgreementsStepRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '/_initialized': typeof InitializedRouteWithChildren
  '/acceptStoragePermissions': typeof AcceptStoragePermissionsLazyRoute
  '/storagePermissionsAcknowledgement': typeof StoragePermissionsAcknowledgementLazyRoute
  '/_initialized/accountCreated': typeof InitializedAccountCreatedRoute
  '/_initialized/accountTypeSelection': typeof InitializedAccountTypeSelectionRoute
  '/_initialized/appQrCode': typeof InitializedAppQrCodeRoute
  '/_initialized/cardInfo': typeof InitializedCardInfoRoute
  '/_initialized/confirmOrder': typeof InitializedConfirmOrderRoute
  '/_initialized/directAccountSetup': typeof InitializedDirectAccountSetupRoute
  '/_initialized/directDepositAuthorization': typeof InitializedDirectDepositAuthorizationRoute
  '/_initialized/existingAccountCheck': typeof InitializedExistingAccountCheckRoute
  '/_initialized/linkAccount': typeof InitializedLinkAccountRoute
  '/_initialized/linkDirectAccount': typeof InitializedLinkDirectAccountRoute
  '/_initialized/otpVerification': typeof InitializedOtpVerificationRouteWithChildren
  '/_initialized/phoneVerification': typeof InitializedPhoneVerificationRoute
  '/_initialized/terms': typeof InitializedTermsRouteWithChildren
  '/_initialized/verificationSelection': typeof InitializedVerificationSelectionRoute
  '/_initialized/verifyAccountInfo': typeof InitializedVerifyAccountInfoRoute
  '/_initialized/workerLookup': typeof InitializedWorkerLookupRoute
  '/_initialized/confirmPasscode': typeof InitializedConfirmPasscodeLazyRoute
  '/_initialized/dateOfBirth': typeof InitializedDateOfBirthLazyRoute
  '/_initialized/downloadApp': typeof InitializedDownloadAppLazyRoute
  '/_initialized/linkedAccountSignIn': typeof InitializedLinkedAccountSignInLazyRoute
  '/_initialized/login': typeof InitializedLoginLazyRoute
  '/_initialized/missingAccountInfo': typeof InitializedMissingAccountInfoLazyRoute
  '/_initialized/orderConfirmed': typeof InitializedOrderConfirmedLazyRoute
  '/_initialized/orgPasscode': typeof InitializedOrgPasscodeLazyRoute
  '/_initialized/passcode': typeof InitializedPasscodeLazyRoute
  '/_initialized/welcome': typeof InitializedWelcomeLazyRoute
  '/_initialized/whatIsBranch': typeof InitializedWhatIsBranchLazyRoute
  '/_initialized/whatIsBranchDirect': typeof InitializedWhatIsBranchDirectLazyRoute
  '/_initialized/workerPasscode': typeof InitializedWorkerPasscodeLazyRoute
  '/_initialized/address/addressLookup': typeof InitializedAddressAddressLookupLazyRoute
  '/_initialized/address/manualAddressForm': typeof InitializedAddressManualAddressFormLazyRoute
  '/_initialized/address/poBoxError': typeof InitializedAddressPoBoxErrorLazyRoute
  '/_initialized/address/verificationResults': typeof InitializedAddressVerificationResultsLazyRoute
  '/_initialized/otpVerification/directAccount': typeof InitializedOtpVerificationDirectAccountLazyRoute
  '/_initialized/otpVerification/walletAccount': typeof InitializedOtpVerificationWalletAccountLazyRoute
  '/_initialized/otpVerification/': typeof InitializedOtpVerificationIndexRoute
  '/_initialized/terms/': typeof InitializedTermsIndexLazyRoute
  '/_initialized/terms/agreements/$step': typeof InitializedTermsAgreementsStepRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/$'
    | ''
    | '/acceptStoragePermissions'
    | '/storagePermissionsAcknowledgement'
    | '/accountCreated'
    | '/accountTypeSelection'
    | '/appQrCode'
    | '/cardInfo'
    | '/confirmOrder'
    | '/directAccountSetup'
    | '/directDepositAuthorization'
    | '/existingAccountCheck'
    | '/linkAccount'
    | '/linkDirectAccount'
    | '/otpVerification'
    | '/phoneVerification'
    | '/terms'
    | '/verificationSelection'
    | '/verifyAccountInfo'
    | '/workerLookup'
    | '/confirmPasscode'
    | '/dateOfBirth'
    | '/downloadApp'
    | '/linkedAccountSignIn'
    | '/login'
    | '/missingAccountInfo'
    | '/orderConfirmed'
    | '/orgPasscode'
    | '/passcode'
    | '/welcome'
    | '/whatIsBranch'
    | '/whatIsBranchDirect'
    | '/workerPasscode'
    | '/address/addressLookup'
    | '/address/manualAddressForm'
    | '/address/poBoxError'
    | '/address/verificationResults'
    | '/otpVerification/directAccount'
    | '/otpVerification/walletAccount'
    | '/otpVerification/'
    | '/terms/'
    | '/terms/agreements/$step'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/$'
    | ''
    | '/acceptStoragePermissions'
    | '/storagePermissionsAcknowledgement'
    | '/accountCreated'
    | '/accountTypeSelection'
    | '/appQrCode'
    | '/cardInfo'
    | '/confirmOrder'
    | '/directAccountSetup'
    | '/directDepositAuthorization'
    | '/existingAccountCheck'
    | '/linkAccount'
    | '/linkDirectAccount'
    | '/phoneVerification'
    | '/verificationSelection'
    | '/verifyAccountInfo'
    | '/workerLookup'
    | '/confirmPasscode'
    | '/dateOfBirth'
    | '/downloadApp'
    | '/linkedAccountSignIn'
    | '/login'
    | '/missingAccountInfo'
    | '/orderConfirmed'
    | '/orgPasscode'
    | '/passcode'
    | '/welcome'
    | '/whatIsBranch'
    | '/whatIsBranchDirect'
    | '/workerPasscode'
    | '/address/addressLookup'
    | '/address/manualAddressForm'
    | '/address/poBoxError'
    | '/address/verificationResults'
    | '/otpVerification/directAccount'
    | '/otpVerification/walletAccount'
    | '/otpVerification'
    | '/terms'
    | '/terms/agreements/$step'
  id:
    | '__root__'
    | '/'
    | '/$'
    | '/_initialized'
    | '/acceptStoragePermissions'
    | '/storagePermissionsAcknowledgement'
    | '/_initialized/accountCreated'
    | '/_initialized/accountTypeSelection'
    | '/_initialized/appQrCode'
    | '/_initialized/cardInfo'
    | '/_initialized/confirmOrder'
    | '/_initialized/directAccountSetup'
    | '/_initialized/directDepositAuthorization'
    | '/_initialized/existingAccountCheck'
    | '/_initialized/linkAccount'
    | '/_initialized/linkDirectAccount'
    | '/_initialized/otpVerification'
    | '/_initialized/phoneVerification'
    | '/_initialized/terms'
    | '/_initialized/verificationSelection'
    | '/_initialized/verifyAccountInfo'
    | '/_initialized/workerLookup'
    | '/_initialized/confirmPasscode'
    | '/_initialized/dateOfBirth'
    | '/_initialized/downloadApp'
    | '/_initialized/linkedAccountSignIn'
    | '/_initialized/login'
    | '/_initialized/missingAccountInfo'
    | '/_initialized/orderConfirmed'
    | '/_initialized/orgPasscode'
    | '/_initialized/passcode'
    | '/_initialized/welcome'
    | '/_initialized/whatIsBranch'
    | '/_initialized/whatIsBranchDirect'
    | '/_initialized/workerPasscode'
    | '/_initialized/address/addressLookup'
    | '/_initialized/address/manualAddressForm'
    | '/_initialized/address/poBoxError'
    | '/_initialized/address/verificationResults'
    | '/_initialized/otpVerification/directAccount'
    | '/_initialized/otpVerification/walletAccount'
    | '/_initialized/otpVerification/'
    | '/_initialized/terms/'
    | '/_initialized/terms/agreements/$step'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  SplatRoute: typeof SplatRoute
  InitializedRoute: typeof InitializedRouteWithChildren
  AcceptStoragePermissionsLazyRoute: typeof AcceptStoragePermissionsLazyRoute
  StoragePermissionsAcknowledgementLazyRoute: typeof StoragePermissionsAcknowledgementLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  SplatRoute: SplatRoute,
  InitializedRoute: InitializedRouteWithChildren,
  AcceptStoragePermissionsLazyRoute: AcceptStoragePermissionsLazyRoute,
  StoragePermissionsAcknowledgementLazyRoute:
    StoragePermissionsAcknowledgementLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$",
        "/_initialized",
        "/acceptStoragePermissions",
        "/storagePermissionsAcknowledgement"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/$": {
      "filePath": "$.tsx"
    },
    "/_initialized": {
      "filePath": "_initialized.tsx",
      "children": [
        "/_initialized/accountCreated",
        "/_initialized/accountTypeSelection",
        "/_initialized/appQrCode",
        "/_initialized/cardInfo",
        "/_initialized/confirmOrder",
        "/_initialized/directAccountSetup",
        "/_initialized/directDepositAuthorization",
        "/_initialized/existingAccountCheck",
        "/_initialized/linkAccount",
        "/_initialized/linkDirectAccount",
        "/_initialized/otpVerification",
        "/_initialized/phoneVerification",
        "/_initialized/terms",
        "/_initialized/verificationSelection",
        "/_initialized/verifyAccountInfo",
        "/_initialized/workerLookup",
        "/_initialized/confirmPasscode",
        "/_initialized/dateOfBirth",
        "/_initialized/downloadApp",
        "/_initialized/linkedAccountSignIn",
        "/_initialized/login",
        "/_initialized/missingAccountInfo",
        "/_initialized/orderConfirmed",
        "/_initialized/orgPasscode",
        "/_initialized/passcode",
        "/_initialized/welcome",
        "/_initialized/whatIsBranch",
        "/_initialized/whatIsBranchDirect",
        "/_initialized/workerPasscode",
        "/_initialized/address/addressLookup",
        "/_initialized/address/manualAddressForm",
        "/_initialized/address/poBoxError",
        "/_initialized/address/verificationResults"
      ]
    },
    "/acceptStoragePermissions": {
      "filePath": "acceptStoragePermissions.lazy.tsx"
    },
    "/storagePermissionsAcknowledgement": {
      "filePath": "storagePermissionsAcknowledgement.lazy.tsx"
    },
    "/_initialized/accountCreated": {
      "filePath": "_initialized/accountCreated.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/accountTypeSelection": {
      "filePath": "_initialized/accountTypeSelection.ts",
      "parent": "/_initialized"
    },
    "/_initialized/appQrCode": {
      "filePath": "_initialized/appQrCode.ts",
      "parent": "/_initialized"
    },
    "/_initialized/cardInfo": {
      "filePath": "_initialized/cardInfo.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/confirmOrder": {
      "filePath": "_initialized/confirmOrder.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/directAccountSetup": {
      "filePath": "_initialized/directAccountSetup.ts",
      "parent": "/_initialized"
    },
    "/_initialized/directDepositAuthorization": {
      "filePath": "_initialized/directDepositAuthorization.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/existingAccountCheck": {
      "filePath": "_initialized/existingAccountCheck.ts",
      "parent": "/_initialized"
    },
    "/_initialized/linkAccount": {
      "filePath": "_initialized/linkAccount.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/linkDirectAccount": {
      "filePath": "_initialized/linkDirectAccount.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/otpVerification": {
      "filePath": "_initialized/otpVerification.ts",
      "parent": "/_initialized",
      "children": [
        "/_initialized/otpVerification/directAccount",
        "/_initialized/otpVerification/walletAccount",
        "/_initialized/otpVerification/"
      ]
    },
    "/_initialized/phoneVerification": {
      "filePath": "_initialized/phoneVerification.ts",
      "parent": "/_initialized"
    },
    "/_initialized/terms": {
      "filePath": "_initialized/terms.tsx",
      "parent": "/_initialized",
      "children": [
        "/_initialized/terms/",
        "/_initialized/terms/agreements/$step"
      ]
    },
    "/_initialized/verificationSelection": {
      "filePath": "_initialized/verificationSelection.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/verifyAccountInfo": {
      "filePath": "_initialized/verifyAccountInfo.ts",
      "parent": "/_initialized"
    },
    "/_initialized/workerLookup": {
      "filePath": "_initialized/workerLookup.ts",
      "parent": "/_initialized"
    },
    "/_initialized/confirmPasscode": {
      "filePath": "_initialized/confirmPasscode.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/dateOfBirth": {
      "filePath": "_initialized/dateOfBirth.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/downloadApp": {
      "filePath": "_initialized/downloadApp.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/linkedAccountSignIn": {
      "filePath": "_initialized/linkedAccountSignIn.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/login": {
      "filePath": "_initialized/login.lazy.ts",
      "parent": "/_initialized"
    },
    "/_initialized/missingAccountInfo": {
      "filePath": "_initialized/missingAccountInfo.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/orderConfirmed": {
      "filePath": "_initialized/orderConfirmed.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/orgPasscode": {
      "filePath": "_initialized/orgPasscode.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/passcode": {
      "filePath": "_initialized/passcode.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/welcome": {
      "filePath": "_initialized/welcome.lazy.ts",
      "parent": "/_initialized"
    },
    "/_initialized/whatIsBranch": {
      "filePath": "_initialized/whatIsBranch.lazy.ts",
      "parent": "/_initialized"
    },
    "/_initialized/whatIsBranchDirect": {
      "filePath": "_initialized/whatIsBranchDirect.lazy.ts",
      "parent": "/_initialized"
    },
    "/_initialized/workerPasscode": {
      "filePath": "_initialized/workerPasscode.lazy.ts",
      "parent": "/_initialized"
    },
    "/_initialized/address/addressLookup": {
      "filePath": "_initialized/address/addressLookup.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/manualAddressForm": {
      "filePath": "_initialized/address/manualAddressForm.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/poBoxError": {
      "filePath": "_initialized/address/poBoxError.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/verificationResults": {
      "filePath": "_initialized/address/verificationResults.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/otpVerification/directAccount": {
      "filePath": "_initialized/otpVerification/directAccount.lazy.ts",
      "parent": "/_initialized/otpVerification"
    },
    "/_initialized/otpVerification/walletAccount": {
      "filePath": "_initialized/otpVerification/walletAccount.lazy.ts",
      "parent": "/_initialized/otpVerification"
    },
    "/_initialized/otpVerification/": {
      "filePath": "_initialized/otpVerification/index.ts",
      "parent": "/_initialized/otpVerification"
    },
    "/_initialized/terms/": {
      "filePath": "_initialized/terms/index.lazy.tsx",
      "parent": "/_initialized/terms"
    },
    "/_initialized/terms/agreements/$step": {
      "filePath": "_initialized/terms/agreements.$step.tsx",
      "parent": "/_initialized/terms"
    }
  }
}
ROUTE_MANIFEST_END */
