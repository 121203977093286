import { StepValues } from '../workers'

export type OrganizationSettings = {
  app_identifier: string
  enable_marketplace_tab: boolean
  app_display_name: string
}

export type PayrollSettings = {
  integration_partner_key: string
  display_name: string
}

export type Organization = {
  id: string
  key: string
  name: string
  login_deep_link: string
  logo_url: string | null
  settings: OrganizationSettings
  payroll_settings: PayrollSettings
}

export type MappedOrganization = Organization & {
  app_identifier: string
}

export const integrationTypes = ['BRANCH', 'BULLHORN', 'PAYLOCITY'] as const
export type IntegrationTypes = (typeof integrationTypes)[number]

export type GetExternalOrganizationPayload = {
  external_org_id: string
  type: IntegrationTypes
}

export type VerifyOrgPasscodeResponse = {
  token: string
}

export type VerifyOrgPasscodePayload = {
  passcode: string
}

export type GetOrgFeesResponse = {
  fee_percent: number
  base_fee: number
  org_covered_percent: number | null
}

export type OrgEnabledAccountTypes =
  | 'DIRECT'
  | 'WALLET_ESSENTIAL'
  | 'WALLET_PREMIER'

export type GetOrgConfigResponse = {
  steps: StepValues[]
  enabled_account_types: OrgEnabledAccountTypes[]
}
