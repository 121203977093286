import { createFileRoute } from '@tanstack/react-router'

import { redirect } from '@/lib/appRedirect/appRedirect'
import { orgFeesQueryOptions } from '@/queryOptions/organizations'

export const Route = createFileRoute('/_initialized/accountTypeSelection')({
  beforeLoad: ({ search }) => {
    const accountType = search.account_type
    if (!accountType) {
      return
    }

    const isDirect = accountType === 'DIRECT'

    if (isDirect) {
      redirect({ to: '/whatIsBranchDirect', throw: true })
    }
    redirect({ to: '/whatIsBranch', throw: true })
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(orgFeesQueryOptions),
})
