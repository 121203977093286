import { fallback, zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { integrationTypes } from '@/api/organizations'
import { AccountTypes } from '@/types/accounts'

const schema = z.object({
  type: fallback(
    z.union([z.enum(integrationTypes), z.undefined()]),
    undefined
  ).optional(),
  account_type: fallback(
    z.union([z.nativeEnum(AccountTypes), z.undefined()]),
    undefined
  ).optional(),
  org_id: z.coerce.string().optional(),
  external_org_id: z.coerce.string().optional(),
  external_worker_id: z.coerce.string().optional(),
  worker_id: z.coerce.string().optional(),
})

export const rootSearchParams = schema.keyof()._def.values
export type RootSearchParams = (typeof rootSearchParams)[number]

export const rootSearchSchema = zodValidator(schema)
export type RootSearchSchema = z.infer<typeof schema>
