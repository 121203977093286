import styled from 'styled-components'

export const OrgProfileImage = styled.img`
  width: 100%;
`

export const OrgProfileImageContainer = styled.div`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.radii.rounded};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[1]};
`
