import { createFileRoute, retainSearchParams } from '@tanstack/react-router'

import { termsSearchSchema } from '@/lib/searchSchemas/terms'

export const Route = createFileRoute('/_initialized/terms')({
  validateSearch: termsSearchSchema,
  search: {
    middlewares: [retainSearchParams(['agreementType'])],
  },
})
