import { Card, Typography } from '@branch-messenger/willow-ui'
import { css } from 'styled-components'

import {
  OrgProfileImage,
  OrgProfileImageContainer,
} from '@/components/orgInfoCard/styles'

import { OrgInfoCardProps } from './types'

export const OrgInfoCard = ({
  logo_url,
  orgName,
  description,
}: OrgInfoCardProps) => {
  return (
    <Card
      $display="flex"
      $gap={4}
      $radii="lg"
      css={css`
        padding: ${({ theme }) => theme.space[6]};
      `}
    >
      <OrgProfileImageContainer>
        {logo_url ? (
          <OrgProfileImage src={logo_url} alt={orgName} />
        ) : (
          <Typography $bold>{orgName.charAt(0)}</Typography>
        )}
      </OrgProfileImageContainer>
      <Typography $bold>{description || orgName}</Typography>
    </Card>
  )
}
