import { createFileRoute } from '@tanstack/react-router'

import { redirect } from '@/lib/appRedirect/appRedirect'

export const Route = createFileRoute('/_initialized/verifyAccountInfo')({
  beforeLoad: ({ context: { createWalletFormStore } }) => {
    const dob = createWalletFormStore.getState().date_of_birth
    const address = createWalletFormStore.getState().address
    if (!dob) {
      redirect({ to: '/dateOfBirth', throw: true })
    } else if (!address?.streetLine) {
      redirect({ to: '/address/addressLookup', throw: true })
    }
  },
})
