import { QueryClient } from '@tanstack/react-query'
import {
  createRootRouteWithContext,
  retainSearchParams,
} from '@tanstack/react-router'
import { StoreApi, UseBoundStore } from 'zustand'

import { AppError, AppLayout } from '@/components/appLayout'
import { AppStore } from '@/store/app.types'
import { CreateAccountForm } from '@/store/createAccountForm.types'

import { rootSearchSchema } from '../lib/searchSchemas/rootSearchSchema'

type RouteContext = {
  queryClient: QueryClient
  appStore: StoreApi<AppStore>
  createWalletFormStore: UseBoundStore<StoreApi<CreateAccountForm>>
}

export const Route = createRootRouteWithContext<RouteContext>()({
  validateSearch: rootSearchSchema,
  search: {
    middlewares: [
      retainSearchParams([
        'account_type',
        'external_org_id',
        'external_worker_id',
        'org_id',
        'type',
        'worker_id',
      ]),
    ],
  },
  component: AppLayout,
  errorComponent: AppError,
})
