import { createFileRoute } from '@tanstack/react-router'
import queryString from 'query-string'

import { Organization } from '@/api/organizations'
import { getExternalOrganization } from '@/api/organizations'
import { initializeOnboardingSession } from '@/api/workers'
import { RouteError } from '@/errors/RouteError'
import { redirect } from '@/lib/appRedirect/appRedirect'
import {
  hasStorageAccess,
  requiresStoragePermissions,
} from '@/lib/cookiePermissions'
import { featureEnabled } from '@/lib/featureFlag'
import { handleAccountTypeSearchParam } from '@/lib/routeLoaderHelpers/home'
import { homeSearchSchema } from '@/lib/searchSchemas/home'
import { ORG_QUERY_KEY, orgQueryOptions } from '@/queryOptions/organizations'
import { workerOnboardingStateQueryOptions } from '@/queryOptions/workers'
import { ACKNOWLEDGED_STORAGE_POPUP } from '@/routeComponents/acceptStoragePermissions/types'

export const Route = createFileRoute('/')({
  validateSearch: homeSearchSchema,
  loaderDeps: ({ search }) => search,
  loader: async ({ deps, context: { queryClient, appStore } }) => {
    const {
      external_org_id,
      org_id,
      type = 'BRANCH',
      worker_id,
      external_worker_id,
      acceptedStoragePermissions,
      acknowledgedStoragePermissionsPopup,
      account_type,
    } = deps

    if (
      !worker_id &&
      !external_worker_id &&
      !featureEnabled('VITE_DIRECT_ONBOARDING')
    ) {
      throw new RouteError(
        'worker_id missing. Please make sure you have the correct link.'
      )
    }

    if (
      requiresStoragePermissions() &&
      !acceptedStoragePermissions &&
      !acknowledgedStoragePermissionsPopup
    ) {
      // When served in an iframe in safari, we need to request storage access to use cookies
      const storageAccess = await hasStorageAccess()
      if (!storageAccess) {
        window
          .open(
            `${import.meta.env.VITE_BASE_URL}/storagePermissionsAcknowledgement?${queryString.stringify(deps)}`,
            'mozillaWindow',
            'popup,width=600,height=600'
          )
          ?.focus()
        redirect({ to: '/acceptStoragePermissions', throw: true })
      }
    }

    let searchParams = {
      external_org_id,
      org_id,
      type,
      worker_id,
      external_worker_id,
      account_type,
    }
    let orgData: Organization | undefined
    if (org_id) {
      orgData = await queryClient.ensureQueryData(orgQueryOptions(org_id))
    } else if (external_org_id) {
      const { data } = await getExternalOrganization({ external_org_id, type })
      queryClient.setQueryData([ORG_QUERY_KEY, data.id], data)
      searchParams = { ...searchParams, org_id: data.id }
      orgData = data
    }

    if (!orgData) {
      throw new RouteError(
        'Organization details not found. Please make sure you have the correct link.'
      )
    }

    if (account_type) {
      await handleAccountTypeSearchParam({
        account_type,
        queryClient,
        orgId: orgData.id,
      })
    }

    appStore.getState().setAppInitialized(true)
    await initializeOnboardingSession({ org_id: orgData.id })
    const onboardingState = await queryClient.ensureQueryData(
      workerOnboardingStateQueryOptions
    )

    if (acknowledgedStoragePermissionsPopup) {
      const windowOpener = window.opener as Window | null
      windowOpener?.postMessage(
        ACKNOWLEDGED_STORAGE_POPUP,
        import.meta.env.VITE_BASE_URL
      )
      return window.close()
    }

    if (
      !worker_id &&
      !external_worker_id &&
      featureEnabled('VITE_DIRECT_ONBOARDING')
    ) {
      redirect({ to: '/welcome', search: searchParams, throw: true })
    }

    if (onboardingState.cur_step === 'ORG_PASSCODE') {
      redirect({ to: '/orgPasscode', search: searchParams, throw: true })
    }

    redirect({ to: '/terms', search: searchParams, throw: true })
  },
})
