import { NavigateOptions, useNavigate } from '@tanstack/react-router'

import { useNavigationHistoryStore } from '@/store/navigationHistory'

import { AppNavigateProps } from './types'

/**
 *
 * Custom navigation that sets replace to true and appends the current search params
 * We need to replace routes since this application will be embedded in an iframe. This will prevent the browser back button from navigating the iframe.
 *
 */
export const useAppNavigate = () => {
  const navigate = useNavigate()
  const addToHistory = useNavigationHistoryStore(store => store.addToHistory)
  const appNavigate = ({
    saveHistory = true,
    ...restOptions
  }: AppNavigateProps) => {
    const navigateOptions: NavigateOptions = {
      replace: true,
      ...restOptions,
    }
    saveHistory && addToHistory(navigateOptions)
    return navigate(navigateOptions)
  }

  return appNavigate
}
