import { redirect as tanstackRedirect } from '@tanstack/react-router'

import { useNavigationHistoryStore } from '@/store/navigationHistory'

import { AppRedirectProps } from './types'
export const redirect = ({
  throw: shouldThrow,
  statusCode,
  headers,
  ...restProps
}: AppRedirectProps) => {
  const navigationStore = useNavigationHistoryStore.getState()
  navigationStore.popHistory()
  navigationStore.addToHistory(restProps)
  tanstackRedirect({
    ...restProps,
    statusCode,
    headers,
    throw: shouldThrow,
  })
}
