import { createFileRoute } from '@tanstack/react-router'

import { getWorkerId, GetWorkerIdResponse } from '@/api/workers'
import { RouteError } from '@/errors/RouteError'
import { redirect } from '@/lib/appRedirect/appRedirect'
import { getWorkerOtpInfoQueryOptions } from '@/queryOptions/workers'

export const Route = createFileRoute('/_initialized/verificationSelection')({
  beforeLoad: async ({
    search: { external_worker_id, worker_id, ...restSearch },
  }) => {
    if (external_worker_id && !worker_id) {
      let workerIdResponse: GetWorkerIdResponse
      try {
        const response = await getWorkerId({
          external_worker_id,
        })
        workerIdResponse = response.data
      } catch (error) {
        throw new RouteError('Unable to get worker id from external worker id')
      }

      redirect({
        to: '/verificationSelection',
        search: {
          worker_id: workerIdResponse.worker_id,
          ...restSearch,
        },
        throw: true,
      })
    }
  },
  loaderDeps: ({ search: { worker_id } }) => ({
    worker_id,
  }),
  loader: async ({ context: { queryClient }, deps: { worker_id } }) => {
    const { email, phone } = await queryClient.ensureQueryData(
      getWorkerOtpInfoQueryOptions({ worker_id: worker_id! })
    )

    if (!email && !phone) {
      redirect({
        to: '/missingAccountInfo',
        throw: true,
      })
    }
  },
})
