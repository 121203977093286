import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { VerificationTypes } from '@/api/workers'

const schema = z.object({
  verificationType: z.nativeEnum(VerificationTypes),
})

export const otpVerificationSearchSchema = zodValidator(schema)
export type OTPVerificationSearchSchema = z.infer<typeof schema>
