import { Box } from '@branch-messenger/willow-ui'
import { useSearch } from '@tanstack/react-router'
import queryString from 'query-string'
import { FC } from 'react'

import { VerificationType } from '@/api/workers'
import SelectedCardIllustration from '@/assets/illustrations/selected-card.svg?react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardButtonLink,
  ContentCardFooter,
  ContentCardHeading,
} from '@/components/contentCard'
import { OrgInfoCard } from '@/components/orgInfoCard'
import { useCreateDirectAccountMutationState } from '@/queries/account'

const matchTypeMap: Record<VerificationType, string> = {
  EMAIL: 'email',
  PHONE: 'phone number',
}

export const LinkDirectAccount: FC = () => {
  const { org_id = '' } = useSearch({ from: '/_initialized/linkDirectAccount' })
  const { linked_orgs = [], track_id } =
    useCreateDirectAccountMutationState() || {}

  const { logo_url, match_type = [], name } = linked_orgs[0] || {}
  const matchTypeDisplay =
    match_type.length > 1
      ? 'email and phone number'
      : matchTypeMap[match_type[0]]

  const query = queryString.stringify({ track_id, org_id, org_name: name })
  return (
    <ContentCard>
      <ContentCardBody>
        <Box $display="flex" $justify="center">
          <SelectedCardIllustration />
        </Box>
        <ContentCardHeading
          subtitle={`We found the following account linked to your ${matchTypeDisplay}. If you feel this is an error, please contact Branch support.`}
        >
          It looks like you already have an account.
        </ContentCardHeading>
        {name && <OrgInfoCard orgName={name} logo_url={logo_url} />}
      </ContentCardBody>
      {track_id && (
        <ContentCardFooter>
          <ContentCardButtonLink
            href={`${import.meta.env.VITE_DIRECT_ONBOARDING_URL}/login?${query}`}
          >
            Log In To Link Accounts
          </ContentCardButtonLink>
        </ContentCardFooter>
      )}
    </ContentCard>
  )
}
