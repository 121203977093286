import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'

import { VerifyOrgPasscodePayload } from '@/api/organizations'
import { verifyOrgPasscode } from '@/api/organizations'
import { useAppNavigate } from '@/hooks/useAppNavigate/useAppNavigate'
import {
  orgConfigQueryOptions,
  orgQueryOptions,
} from '@/queryOptions/organizations'
import { useAlertStore } from '@/store/alert'

export const useOrg = () => {
  const { org_id } = useSearch({ strict: false })
  return useSuspenseQuery(orgQueryOptions(org_id!))
}

export const useOrgConfig = () => {
  const { org_id } = useSearch({ strict: false })
  return useSuspenseQuery(orgConfigQueryOptions(org_id!))
}

export const useVerifyOrgPasscodeMutation = () => {
  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)

  return useMutation({
    mutationFn: (payload: VerifyOrgPasscodePayload) =>
      verifyOrgPasscode(payload),
    onSuccess: () =>
      navigate({
        to: '/terms',
      }),
    onError: () => {
      showAlert({
        title: 'Incorrect passcode.',
        description:
          'Please check your spelling or reach out to your manager for the correct passcode.',
        hideCancel: true,
      })
    },
  })
}
